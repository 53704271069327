import React, { useState } from "react"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import { PageProps } from "gatsby"
import Section from "../../components/Section"
import Wrapper from "../../components/Wrapper"
import { useForm } from "react-hook-form"
import { FaCheckCircle } from "react-icons/fa"
import SEO from "../../components/SEO"
import Company from "../../components/sections/Company"

export default function Position2(props: PageProps) {
  const [status, setStatus] = useState(false)
  const { register, handleSubmit, reset } = useForm()
  const onSubmit = data => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(data).toString(),
    })
      .then(() => {
        reset()
        setStatus(true)
      })
      .catch(error => alert(error))
  }
  return (
    <React.Fragment>
      <SEO titleAddition="Position - Junior Golang Developer" />
      <Header />
      <div className="w-full overflow-x-hidden flex flex-col  text-gray-700">
        <Section>
          <Wrapper>
            <div
              className=""
              css={{
                minHeight: "calc(100vh - 5rem)",
              }}
            >
              Junior Golang Developer
            </div>
          </Wrapper>
        </Section>
        <Section>
          <Wrapper>
            <Footer />
          </Wrapper>
        </Section>
      </div>
    </React.Fragment>
  )
}
